<template>
    <div>
        <div class="position-relative">
            <section class="section-shaped my-0">
                <div class="shape shape-style-1 shape-skew banner">
                </div>
                <!-- Logo & Description -->
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-2">
                            </div>
                            <div class="col-lg-8">
                                <h1 class="lead text-white">N'h&eacute;sitez pas à nous contacter</h1>
                                <p class="lead text-white">
                                    Nous restons à votre disposition pour toutes informations complémentaires
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-- Formulaire -->
        <formulaire-contact/>

        <div class="container shape-container d-flex">
            <div class="col px-0">
                <div class="row">
                    <p class="lead">
                        Vous pouvez aussi nous contacter par :
                        <ul>
                            <li>Email : <b><a class="text-default" href="mail:contact@pgassistante.fr">contact@pgassistante.fr</a></b></li>
                            <li>T&eacute;l&eacute;phone : <b><a class="text-default" href="tel:0748111186">07.48.11.11.86</a></b></li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import FormulaireContact from "./components/FormulaireContact.vue";
export default {
  name: "contact",
  components: {
      FormulaireContact
  },
  data() {
    return {
      showSuccessAlert: false,
      showFailedAlert: false
    };
  },
  methods: {
      send: function () {
          const success = true;
          if (success) {
              this.showFailedAlert = false;
              this.showSuccessAlert = true;
          } else {
              this.showSuccessAlert = false;
              this.showFailedAlert = true;
          }
      }
  }
};
</script>
