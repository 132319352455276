<template>
  <div>
    <section class="section section-lg pt-lg-0 section-contact-us">
      <div class="container">
        <div class="row justify-content-center mt--300">
          <div class="col-lg-8">
            <card gradient="secondary" shadow body-classes="p-lg-5">
              <form>
                <h4 class="mb-1">Formulaire de contact</h4>
                <p class="mt-0">Nous sommes à votre disposition</p>
                <!-- Nom -->
                <div class="form-group mt5 input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="ni ni-user-run"></i
                    ></span>
                  </div>
                  <input
                    aria-describedby="addon-right addon-left"
                    placeholder="Votre nom"
                    class="form-control"
                    v-model="form.nom"
                    required
                  />
                </div>
                <!-- Email -->
                <div class="form-group input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="ni ni-email-83"></i
                    ></span>
                  </div>
                  <input
                    type="email"
                    aria-describedby="addon-right addon-left"
                    placeholder="Email"
                    class="form-control"
                    v-model="form.email"
                    required
                  />
                </div>
                <!-- Email -->
                <div class="form-group input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="fa fa-phone"></i
                    ></span>
                  </div>
                  <input
                    aria-describedby="addon-right addon-left"
                    placeholder="Téléphone"
                    class="form-control"
                    v-model="form.tel"
                    required
                  />
                </div>
                <div class="form-group mb4 input-group">
                  <textarea
                    class="form-control form-control"
                    name="name"
                    rows="4"
                    cols="80"
                    placeholder="Votre message..."
                    v-model="form.message"
                    required
                  ></textarea>
                </div>
                <!-- Alert -->
                <div class="zone-alert">
                  <base-alert
                    v-if="alert.invalid.show"
                    type="warning"
                    icon="ni ni-bell-55"
                  >
                    <span slot="text"><strong>Attention !</strong>{{alert.invalid.message}}</span>
                  </base-alert>
                  <base-alert
                    v-if="alert.success.show"
                    type="success"
                    icon="ni ni-like-2"
                  >
                    <span slot="text"><strong>Succ&egrave;s !</strong>{{alert.success.message}}</span>
                  </base-alert>
                  <base-alert
                    v-if="alert.error.show"
                    type="danger"
                    icon="fa fa-warning"
                  >
                    <span slot="text"><strong>Erreur !</strong>{{alert.error.message}}</span>
                  </base-alert>
                </div>
                <base-button type="primary" round block size="lg" @click="send">
                  Envoyer
                </base-button>
              </form>
            </card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

import BaseAlert from "@/components/BaseAlert.vue";
export default {
  name: "formulaire-contact",
  components: {
    BaseAlert,
  },
  data() {
    return {
      alert: {
        invalid: {
          show: false,
          message: " Les données du formulaires sont incomplètes !",
        },
        success: {
            show: false,
            message: " Votre message a bien été envoyé !"
        },
        error: {
            show: false,
            message: " Impossible d'envoyer votre message !"
        }
      },
      form: {
        nom: "",
        email: "",
        tel: "",
        message: "",
        token: "hMD49j3f2x41592Nnc5853P54A1Qd7u1",
      },
    };
  },
  methods: {
    send: function () {
      if (this.form.nom.trim() === "" || this.form.email.trim() === "" || this.form.tel.trim() === "" || this.form.message.trim() === "") {
        this.alert.invalid.message = " Les données du formulaires sont incomplètes !";
        this.alert.invalid.show = true;
        return false;
      }
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email.trim())) {
        this.alert.invalid.message = " L'email est invalide !";
        this.alert.invalid.show = true;
        return false;
      }
      this.alert.invalid.show = false;
      this.alert.success.show = false;
      this.alert.error.show = false;
      axios
        .post("/api/contact.php", this.form)
        .then((res) => {
          if (res.data.status === "ok") {
            this.alert.error.show = false;
            this.alert.success.show = true;
          } else {
            this.alert.success.show = false;
            this.alert.error.show = true;
          }
        })
        .catch((error) => {
          this.alert.success.show = false;
          this.alert.error.show = true;
        })
        .finally(() => {
          this.form.nom = "";
          this.form.email = "";
          this.form.tel = "";
          this.form.message = "";
        });
    },
  },
};
</script>
