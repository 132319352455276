<template>
    <header class="header-global">
        <base-nav class="navbar-main" type=""  expand style="background-color: #f4f5f7">
            <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
                <img src="img/LOGO_PGA.png" class="img-fluid" style="height: 100px">     
            </router-link>

            <div class="row" slot="content-header" slot-scope="{closeMenu}">
                <div class="col-6 collapse-brand">
                    <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
                        <img src="img/LOGO_PGA.png" class="img-fluid" style="height: 100px">  
                    </router-link>
                </div>
                <div class="col-6 collapse-close">
                    <close-button id="close-btn" @click="closeMenu"></close-button>
                </div>
            </div>

            <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
                <li class="nav-item" @click="handleClick">
                    <router-link slot="brand" class="nav-link nav-link-icon" to="/presentation">
                        <i class="fa fa-user nav-txt"></i>
                        <span class="nav-link-inner--text nav-txt">Qui suis-je ?</span>
                    </router-link>
                </li>
                <li @click="handleClick">
                    <router-link slot="brand" class="nav-link nav-link-icon" to="/prestation">
                        <i class="fa fa-briefcase nav-txt"></i>
                        <span class="nav-link-inner--text nav-txt">Nos Prestations</span>
                    </router-link>
                </li>
                <li @click="handleClick">
                    <router-link slot="brand" class="nav-link nav-link-icon" to="/contact">
                        <i class="fa fa-envelope nav-txt"></i>
                        <span class="nav-link-inner--text nav-txt">Contact</span>
                    </router-link>
                </li>
            </ul>
            <ul class="navbar-nav align-items-lg-center ml-lg-auto">
                <!--li class="nav-item">
                    <a class="nav-link nav-link-icon btn-tooltip" href="#" target="_blank" rel="noopener" v-b-tooltip.hover.bottom title="Follow us on Twitter">
                        <i class="fa fa-twitter-square"></i>
                        <span class="nav-link-inner--text d-lg-none">Twitter</span>
                    </a>
                </li-->
                <li class="nav-item">
                    <a class="nav-link nav-link-icon btn-tooltip" href="https://facebook.com/AssistanceGestionAdministrative/" target="_blank" rel="noopener" v-b-tooltip.hover.bottom title="Like us on Facebook">
                        <i class="fa fa-facebook-square nav-txt"></i>
                        <span class="nav-link-inner--text d-lg-none nav-txt">Facebook</span>
                    </a>
                </li>
                <!--li class="nav-item">
                    <a class="nav-link nav-link-icon btn-tooltip" href="#" target="_blank" rel="noopener" v-b-tooltip.hover.bottom title="Follow us on Instagram">
                        <i class="fa fa-instagram"></i>
                        <span class="nav-link-inner--text d-lg-none">Instagram</span>
                    </a>
                </li-->
            </ul>
        </base-nav>
    </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import CloseButton from "@/components/CloseButton";
import { VBTooltip } from "bootstrap-vue/esm/directives/tooltip/tooltip";
export default {
  components: {
    BaseNav,
    CloseButton
  },
  directives: {
    BTooltip: VBTooltip
  },
  methods: {
    handleClick() {
      document.getElementById('close-btn').click();
    }
  }
};
</script>
<style>
    .title {
        color: white;
    }

    .nav-txt {
        color: black;
    }
</style>