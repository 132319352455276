<template>
    <div>
        <div class="position-relative">
            <section class="section-shaped my-0">
                <div class="shape shape-style-1 shape-skew banner">
                </div>
                <!-- Logo & Description -->
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-2">
                            </div>
                            <div class="col-lg-8">
                                <h1 class="lead text-white">Nos prestations</h1>
                                <p class="lead text-white">
                                    Nous étudions chaque demande au cas par cas pour répondre au mieux à vos besoins.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-- Professionnels -->
        <section class="section section-lg">
            <div class="container" id="pro">
                <div class="row row-grid align-items-center">
                    <div class="col-sm-12">
                        <icon name="ni ni-building" class="mb-5" size="lg" type="warning" shadow rounded></icon>
                        <h3>Pour les professionnels</h3>
                    </div>
                </div>
                <div class="row row-grid">
                    <div class="col-md-4">
                        <p>
                            Assistanat commercial :
                        </p>
                        <ul>
                            <li class="py-2">Saisie des devis</li>
                            <li class="py-2">Facturation et suivi des règlements clients</li>
                            <li class="py-2">Préparation et envoi de dossiers d’appel d’offres</li>
                            <li class="py-2">Mise à jour des bases de données clients</li>
                            <li class="py-2">Envoi de courriers en nombre type publipostage</li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <p>
                            Assistanat administratif :
                        </p>
                        <ul>
                            <li class="py-2">Création et/ou rédaction de tous documents (courriers, rapports, compte rendus, contrats, procès verbaux ,etc)</li>
                            <li class="py-2">Gestion des dossiers de formation</li>
                            <li class="py-2">Formalités d'embauche (DPAE, visite médicale, adhésion mutuelle, déclaration organismes sociaux)</li>
                            <li class="py-2">Classement, archivage physique ou numérique</li>
                            <li class="py-2">Organisation d'événements et des déplacements</li>
                            <li class="py-2">Retranscription audio</li>
                        </ul>     
                    </div>
                    <div class="col-md-4">
                        <p>
                            Assistanat comptable :
                        </p>
                        <ul>
                            <li class="py-2">Suivi de trésorerie</li>
                            <li class="py-2">Relance des impayés</li>
                            <li class="py-2">Suivi des factures d'achats</li>
                            <li class="py-2">Préparation et suivi des règlements fournisseurs</li>
                            <li class="py-2">Préparation de remise de chèques</li>
                            <li class="py-2">Rapprochement bancaire</li>
                        </ul>
                    </div>        
                </div>
            </div>
        </section>

        <!-- Particulier -->
        <section class="section section-lg" id="particulier">
            <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-sm-12">
                        <icon name="fa fa-home" class="mb-5" size="lg" type="primary" shadow rounded></icon>
                        <h3>Pour les particuliers</h3>
                    </div>
                </div>
                <div class="row row-grid">
                    <div class="col-md-6">
                        <ul>
                            <li class="py-2">Aide aux démarches administratives après décès (rédaction de courriers, demandes de capitaux décès, constitution de dossiers pour versement d'allocations ou réversion de pension, déclaration de succession, etc)</li>
                            <li class="py-2">Classement / archivage</li>
                            <li class="py-2">Assistance au paiement de factures (chèque ou dématérialisé)</li>
                            <li class="py-2">Assistance dans les démarches administratives courantes</li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <ul>
                            <li class="py-2">Constitution de dossiers complexes (subventions, pensions, allocations, etc)</li>
                            <li class="py-2">Suivi des remboursements de santé</li>
                            <li class="py-2">Rédaction de courriers divers</li>
                            <li class="py-2">Relecture de mémoires</li>
                            <li class="py-2">Rédaction de dossier de fin d'études</li>
                        </ul>     
                    </div>   
                </div>
            </div>
        </section>

        <!-- Contact -->
        <section class="section section-lg pt-lg-0">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="row row-grid">
                            <div class="col-lg-2"></div>
                            <div class="col-lg-8">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="fa fa-envelope" type="success" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-success text-uppercase">Nous contacter</h6>
                                    <p class="description mt-3">
                                        Nos prestations vous int&eacute;resse ?<br/>
                                        N'h&eacute;sitez pas à nous contacter pour que nous puissions en discuter<br/>
                                    </p>
                                    <router-link to="/contact">
                                        <base-button type="success">
                                            Formulaire de contact
                                        </base-button>
                                    </router-link>
                                </card>
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
  name: "prestation",
  components: {}
};
</script>

